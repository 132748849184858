
/* global google */
/* eslint-disable */

import { defineComponent, PropType, watch, ref, reactive } from 'vue';
import { DEFAULT_MAP_OPTIONS, regions } from '@/utils/map';
import { polyProps, markerTypes } from './index.vue';
// control svgs
import blackPlus from '@/assets/svg/plus.svg';
import blackMinus from '@/assets/svg/minus.svg';
import caretDown from '@/assets/svg/caret-down.svg';
import caretUp from '@/assets/svg/caret-up.svg';


export default defineComponent({
  name: 'Map',
  props: {
    paths: {
      type: Array as PropType<Array<polyProps>>,
    },
  },
  setup() {
    const overviewMapRef = ref();
    const zoomLevel = ref(4);
    const infoWindow:polyProps['info'] = reactive({
      name: '',
      totalSurveys: 0,
      surveysInLastTwentyFour: 0,
      totalTrainings: 0,
      trainingsInLastTwentyFour: 0,
      markerType: '',
    });
    const position = reactive({
        lat: -3.8333,
        lng: 29.3677,
    });
    const infoPosition = reactive({
        lat: -3.8333,
        lng: 29.3677,
    });
    const infoOpen = ref(false);
    const hide = ref('' as markerTypes);
    watch(overviewMapRef, (googleMap) => {
      if (googleMap) {
        googleMap.$mapPromise.then((map:any) => {
          // trainings
          const hideUI  = document.createElement('div');
          hideUI.classList.add('overview-hide-controls');
          const hideTrainings = document.createElement('div');
          hideTrainings.classList.add('overview-hide-trainings');
          hideTrainings.textContent = 'Hide Trainings';
          const hideSurveys = document.createElement('div');
          hideSurveys.classList.add('overview-hide-surveys');
          hideSurveys.textContent = 'Hide Surveys';
          hideUI.appendChild(hideTrainings);
          hideTrainings.addEventListener('click', () => {
            if (!hide.value) {
              hide.value = 'training';
              hideTrainings.classList.add('overview-hide-active');
            } else if (hide.value === 'survey') {
              hide.value = 'training';
              hideTrainings.classList.add('overview-hide-active');
              hideSurveys.classList.remove('overview-hide-active');
            } else if (hide.value === 'training') {
              hide.value = '';
              hideTrainings.classList.remove('overview-hide-active');
              hideSurveys.classList.remove('overview-hide-active');
            }
          });
          hideUI.appendChild(hideSurveys);
          hideSurveys.addEventListener('click', () => {
            if (!hide.value) {
              hide.value = 'survey';
              hideSurveys.classList.add('overview-hide-active');
            } else if (hide.value === 'training') {
              hide.value = 'survey';
              hideSurveys.classList.add('overview-hide-active');
              hideTrainings.classList.remove('overview-hide-active');
            } else if (hide.value === 'survey') {
              hide.value = '';
              hideSurveys.classList.remove('overview-hide-active');
              hideTrainings.classList.remove('overview-hide-active');
            }
          });
          // map controls
          const controlUI = document.createElement('div');
          controlUI.classList.add('overview-map-controls');
          const controlPlusContainer = document.createElement('div');
          controlPlusContainer.style.width = '100%';
          const controlPlus = new Image(12);
          controlPlus.src = blackPlus;
          controlPlusContainer.appendChild(controlPlus);
          controlPlusContainer.addEventListener('click', () => {
            zoomLevel.value += 1;
          });
          const controlMinusContainer = document.createElement('div');
          controlMinusContainer.style.width = '100%';
          const controlMinus = new Image();
          controlMinus.src = blackMinus;
          controlMinusContainer.appendChild(controlMinus);
          controlMinusContainer.addEventListener('click', () => {
            if (zoomLevel.value > 2) {
              zoomLevel.value -= 1;
            }
          });
          const navIcon = document.createElement('div');
          navIcon.classList.add('overview-caret-controls');
          const controlCaretDown = new Image();
          controlCaretDown.src = caretDown;
          const controlCaretUp = new Image();
          controlCaretUp.src = caretUp;
          navIcon.appendChild(controlCaretUp);
          navIcon.appendChild(document.createElement('br'));
          navIcon.appendChild(controlCaretDown);
          navIcon.addEventListener('click', () => {
            map.setCenter(position);
          });
          controlUI.appendChild(controlPlusContainer);
          const firstBr = document.createElement('br');
          controlUI.appendChild(firstBr);
          firstBr.classList.add('overview-br');
          controlUI.appendChild(controlMinusContainer);
          const secondBr = document.createElement('br');
          secondBr.classList.add('overview-br');
          controlUI.appendChild(secondBr);
          controlUI.appendChild(navIcon);
          //@ts-expect-error
          map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlUI);
          //@ts-expect-error
          map.controls[google.maps.ControlPosition.TOP_LEFT].push(hideUI);
        });
      }
    });
    return {
      overviewMapRef,
      zoomLevel,
      position,
      infoPosition,
      infoOpen,
      infoWindow,
      hide,
    };
  },
  computed: {
    mapOptions() {
      const mapOptions = { ...DEFAULT_MAP_OPTIONS };
      mapOptions.styles.forEach((feature) => {
        if (feature.featureType == 'administrative.country') {
          feature.stylers.forEach((style:any ) => {
            if (style?.weight) {
              style.weight = '1';
            }
          })
        }
      })
      mapOptions.draggable = true;
      mapOptions.gestureHandling = 'cooperative';
      return mapOptions;
    },
    polygonOptions(){
      return {
        strokeColor: "#c16c00",
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: "#e98300cc",
      };
    },
    markerPaths() {
      const { paths, hide } = this;
      if (hide) {
        if (hide === 'survey') {
          return paths?.filter((p) => (p.info.markerType !== 'survey'));
        } else if (hide === 'training') {
          return paths?.filter((p) => (p.info.markerType !== 'training'));
        }
      } else {
        return paths;
      }
    },
  },
  methods: {
    onPolyClick(event: Event, info: polyProps['info']) {
      
      // @ts-expect-error no types for GMapPolygon
      this.infoPosition.lat = event.latLng.lat();
      // @ts-expect-error no types for GMapPolygon
      this.infoPosition.lng = event.latLng.lng();
      this.infoWindow.name = info.name;
      this.infoWindow.totalSurveys = info.totalSurveys;
      this.infoWindow.totalTrainings = info.totalTrainings;
      this.infoWindow.surveysInLastTwentyFour = info.surveysInLastTwentyFour;
      this.infoWindow.trainingsInLastTwentyFour = info.trainingsInLastTwentyFour;
      this.infoWindow.markerType = info.markerType;
      this.infoOpen = true;
    },
  },
  watch: {
    markerPaths(newProps, oldProps) {
      const location = this.$store.getters['overviewFilter/getLocation'];
      const scope = this.$store.getters['overviewFilter/getGeographicScope'];
      if (location && newProps && newProps.length > 0) {
        if (scope === 'Regional') {
          this.zoomLevel = 3;
          let regionCoords;
          if (location === 'Middle East and North Africa') {
            regionCoords = regions.MiddleEastAndNorthAfrica.position;
          } else if (location === 'East Asia and Pacific') {
            regionCoords = regions.EastAsiaAndPacific.position;
          } else if (location === 'Eastern and Southern Africa') {
            regionCoords = regions.EasternAndSouthernAfrica.position;
          } else if (location === 'Latin America and the Caribbean') {
            regionCoords = regions.LatinAmericaAndTheCaribbean.position;
          } else if (location === 'South Asia') {
            regionCoords = regions.SouthAsia.position;
          } else if (location === 'Europe and Central Asia') {
            regionCoords = regions.EuropeAndCentralAsia.position;
          } else if (location === 'West and Central Africa') {
            regionCoords = regions.WestAndCentralAfrica.position;
          } else if (location === 'North America') {
            regionCoords = regions.NorthAmerica.position;
          }
          if (regionCoords) {
            this.position.lat = regionCoords.lat;
            this.position.lng = regionCoords.lng;
          }
        } else if (scope === 'National') {
          let x = newProps[0].coordinates;
          while (x.length) {
            x = x[0];
          }
          this.position.lat = x.lat;
          this.position.lng = x.lng;
        }
        this.infoOpen = false;
      }
    },
  },
});


import { defineComponent } from 'vue';
import message from 'ant-design-vue/lib/message';
import { Spin } from 'ant-design-vue';
import OverviewFilters from '@/components/Filters/OverviewFilters/index.vue';
import OverviewMap from '@/components/Overview/Map/index.vue';
import Header from '@/components/Overview/Header/index.vue';
import OverviewGraphs from '@/components/Overview/Graphs/index.vue';
import OverviewMetrics from '@/components/Overview/Metrics/index.vue';

export default defineComponent({
  name: 'Overview',
  components: {
    Header,
    OverviewFilters,
    OverviewMap,
    OverviewGraphs,
    OverviewMetrics,
    Spin,
  },
  data() {
    return {
      spinning: false,
    };
  },
  computed: {
    getLoading() {
      return this.$store.getters['overviewFilter/getLoading'];
    },
  },
  async created() {
    try {
      const training = this.$store.getters['training/getDefaultValues'];
      if (training?.length && !training[0]?.length) {
        await Promise.all([this.$store.dispatch('training/updateDefaultData'), this.$store.dispatch('overviewFilter/updateOverview'), this.$store.dispatch('overviewFilter/updateLocationOptions')])
          .then(() => (this.$store.dispatch('overviewFilter/updateTrainingFilterData')));
      } else {
        await this.$store.dispatch('overviewFilter/updateData');
      }
    } catch (e) {
      console.error(e);
    }
  },
  watch: {
    getLoading(newProps, oldProps) {
      if (oldProps === 'loading' && newProps === 'done') {
        message.destroy();
        this.spinning = false;
      } else if (newProps === 'loading') {
        message.loading('Loading data and running calculations...', 0);
        this.spinning = true;
      }
    },
  },
});


import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { Popover } from 'ant-design-vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TotalSurveys',
  components: {
    InfoCircleOutlined,
    Popover,
  },
  computed: {
    totalSurveys() {
      let s = 0;
      const data = this.$store.getters['overviewFilter/getData'];
      if (data && data?.totalSurveys) {
        s = data.totalSurveys;
      }
      return s;
    },
  },
});

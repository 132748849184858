
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { Popover } from 'ant-design-vue';
import { overviewSurveyProps } from '@/utils/request';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TotalCountries',
  components: {
    InfoCircleOutlined,
    Popover,
  },
  computed: {
    getMergedList():number {
      const data:overviewSurveyProps = this.$store.getters['overviewFilter/getData'];
      let survLocs:string[] = [];
      if (data && data?.surveysByCountry) {
        survLocs = Object.keys(data.surveysByCountry);
      }
      const mergedLocs = Array.from(new Set(survLocs.concat(this.getTrainingList)));
      return mergedLocs.length;
    },
    getTrainingList():string[] {
      const values = this.$store.getters['overviewFilter/getTrainingData'];
      const locations = [] as string[];
      if (values && values.length > 0) {
        const codeIndex = values[0].indexOf('Country Code');
        if (codeIndex !== -1) {
          values.forEach((row:string[], key:number) => {
            if (key !== 0) {
              locations.push(row[codeIndex]);
            }
          });
        }
      }
      return locations;
    },
  },
});


import { defineComponent } from 'vue';
import SurveyByYear from '@/components/Overview/Graphs/SurveyByYear/index.vue';
import TrainingByYear from '@/components/Training/Graphs/TrainingByYear/index.vue';

export default defineComponent({
  name: 'OverviewGraphs',
  components: {
    SurveyByYear,
    TrainingByYear,
  },
});

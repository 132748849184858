
import { defineComponent, shallowRef } from 'vue';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import OMap from './OverviewMap.vue';

export type markerTypes = 'survey' | 'training' | 'both' | '';

export interface polyProps {
  coordinates: {lat: number, lng: number}[];
  info: {
    name: string;
    totalSurveys: number;
    surveysInLastTwentyFour: number;
    totalTrainings: number;
    trainingsInLastTwentyFour: number;
    markerType: markerTypes;
  };
}

export default defineComponent({
  name: 'OverviewOMap',
  components: {
    OMap,
  },
  setup() {
    const coordMap = shallowRef(new Map());
    return {
      coordMap,
    };
  },
  data() {
    return {
      paths: [] as polyProps[],
    };
  },
  computed: {
    ...mapGetters('overviewFilter', ['getLoading', 'getData', 'getTrainings', 'getTrainingData']),
  },
  methods: {
    getTrainingMarkers() {
      const values = this.getTrainingData;
      const compareValues:Array<string[]> = this.getTrainings;
      const countryValues = new Map<string, [string[], string, number, number]>();
      const codeIndex = values[0].indexOf('Country Code');
      const yearIndex = values[0].indexOf('Year of Training');
      const countryIndex = values[0].indexOf('Country of Training');
      const dates = this.$store.getters['overviewFilter/getDate'];
      const dateToYear = dates.length > 1 ? dayjs(dates[1]).year() : dayjs().year();
      compareValues.forEach((row: string[]) => {
        const c = countryValues.get(row[codeIndex]);
        if (c) {
          if (c[0][yearIndex] < row[yearIndex]) {
            c[0] = row;
          }
          if (row[yearIndex] && parseInt(row[yearIndex], 10) >= (dateToYear - 2)) {
            c[3] += 1;
          }
          c[2] += 1;
        } else {
          countryValues.set(row[codeIndex], [row, row[countryIndex], 1, 0]);
          if (row[yearIndex] && parseInt(row[yearIndex], 10) >= (dateToYear - 2)) {
            countryValues.set(row[codeIndex], [row, row[countryIndex], 1, 1]);
          } else {
            countryValues.set(row[codeIndex], [row, row[countryIndex], 1, 0]);
          }
        }
      });
      return countryValues;
    },
    getMergedMarkers() {
      const data = this.getData;
      const trainings = this.getTrainingMarkers();
      const trainingCodes = trainings.keys();
      let surveyCodes: string[] = [];
      const markers: Array<[string, string, number, number, number, number, markerTypes]> = [];
      if (data && data.surveysByCountry) {
        surveyCodes = Object.keys(data.surveysByCountry);
      }
      const totalKeys = [...new Set([...trainingCodes, ...surveyCodes])];
      totalKeys.forEach((code: string) => {
        const marker: [string, string, number, number, number, number, markerTypes ] = [code, '', 0, 0, 0, 0, ''];
        const t = trainings.get(code);
        if (t) {
          /* eslint-disable */
          marker[1] = t[1];
          marker[4] = t[2];
          marker[5] = t[3];
          marker[6] = 'training';
          /* eslint-enable */
        }
        if (data.surveysByCountry[code]) {
          marker[1] = data.surveysByCountry[code].country;
          marker[2] = data.surveysByCountry[code].total;
          marker[3] = data.surveysByCountry[code].last24Months;
          if (marker[6]) {
            marker[6] = 'both';
          } else {
            marker[6] = 'survey';
          }
        }
        markers.push(marker);
      });
      return markers;
    },
    async getPaths() {
      const markers = this.getMergedMarkers();
      if (this.coordMap.size === 0) {
        try {
          const fileNames = markers.map(async (marker) => (fetch(`low_res_countries/${marker[0]}.json`).then((res) => (res.json()))));
          const allCoords = await Promise.all(fileNames);

          this.paths = markers.map((marker, i) => {
            this.coordMap.set(marker[0], allCoords[i]);
            return {
              coordinates: allCoords[i],
              info: {
                name: marker[1],
                totalSurveys: marker[2],
                surveysInLastTwentyFour: marker[3],
                totalTrainings: marker[4],
                trainingsInLastTwentyFour: marker[5],
                markerType: marker[6],
              },
            };
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        this.paths = markers.map((marker) => (
          {
            coordinates: this.coordMap.get(marker[0]),
            info: {
              name: marker[1],
              totalSurveys: marker[2],
              surveysInLastTwentyFour: marker[3],
              totalTrainings: marker[4],
              trainingsInLastTwentyFour: marker[5],
              markerType: marker[6],
            },
          }));
      }
    },
  },
  watch: {
    getLoading(newProps, oldProps) {
      if (oldProps === 'loading' && newProps === 'done') {
        this.getPaths();
      }
    },
  },
});

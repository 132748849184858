
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { Popover } from 'ant-design-vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TotalTrainings',
  components: {
    InfoCircleOutlined,
    Popover,
  },
  computed: {
    totalTrainings():number {
      return this.$store.getters['overviewFilter/getTrainings']?.length;
    },
  },
});

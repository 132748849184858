
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { locationProps } from '@/utils/request';
import { Button } from 'ant-design-vue';
import GeographicLocation from '@/components/Filters/GeographicLocation/index.vue';
import DateRangeFilter from '@/components/Filters/DateRangeFilter.vue';

export default defineComponent({
  name: 'OverviewFilters',
  components: {
    GeographicLocation,
    DateRangeFilter,
    Button,
  },
  data() {
    return {
      selected: false,
    };
  },
  props: {
    filterClicked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('overviewFilter', ['getQueryDiff']),
    getLocations() {
      const scope = this.$store.getters['overviewFilter/getGeographicScope'];
      const values = this.$store.getters['training/getDefaultValues'];
      const indexes = [] as number[];
      let locations = [] as string[];
      if (scope && values.length > 0) {
        if (scope === 'National') {
          indexes.push(values[0].indexOf('Country of Training'));
        } else if (scope === 'Regional') {
          indexes.push(values[0].indexOf('Region of Training'));
        } else if (scope === 'All' || !scope) {
          indexes.push(values[0].indexOf('Region of Training'));
          indexes.push(values[0].indexOf('Country of Training'));
        }
        if (!indexes.includes(-1)) {
          values.forEach((v: string[], i: number) => {
            indexes.forEach((index:number) => {
              if (i !== 0 && index <= v.length - 1 && (/\S/).test(v[index]) && !locations.includes(v[index].trim())) locations.push(v[index].trim());
            });
          });
        }
      }
      locations = Array.from(new Set(locations.concat(this.getSurveyLocations)));
      locations = locations.sort((a, b) => (a > b ? 1 : -1));
      return locations.map((key: string) => ({ label: key, value: key }));
    },
    getSurveyLocations() {
      const options: locationProps[] = this.$store.getters['overviewFilter/getLocationOptions'];
      const x:string[] = [];
      const scope = this.$store.getters['overviewFilter/getGeographicScope'];
      if (scope === 'Regional') {
        options.forEach((opt: locationProps) => {
          if (opt?.region && !x?.includes(opt?.region)) x.push(opt.region);
        });
      } else if (scope === 'National') {
        options.forEach((opt: locationProps) => {
          if (opt?.title && !x?.includes(opt?.title)) x.push(opt.title);
        });
      } else if (scope === 'Sub-National') {
        let y: (string[]|undefined)[] = options.flatMap((opt: locationProps) => {
          const children = opt?.children;
          let flatOptOne:(string[]|undefined)[] = [];
          let flatOptTwo:(string[]|undefined)[] = [];
          let flatOptThree:(string[]|undefined)[] = [];
          if (children && children.length > 0) {
            // admin 1
            flatOptOne = children.map((child: locationProps) => ([child.title, opt.title]));
            // admin 2
            flatOptTwo = children.flatMap((adminOne: locationProps) => {
              const adminOneChildren = adminOne?.children;
              let adminTwoChildren:string[][] = [];
              if (adminOneChildren && adminOneChildren.length > 0) {
                adminTwoChildren = adminOneChildren.map((adminTwo: locationProps) => (
                  // add only unique
                  flatOptOne.some((i) => (i && i[0] !== adminTwo.title)) ? [adminTwo.title, opt.title] : []
                ));
              }
              return adminTwoChildren;
            });
            // admin 3
            flatOptThree = children.flatMap((adminOne: locationProps) => {
              const adminOneChildren = adminOne?.children;
              let adminThreeChildren:string[][] = [];
              if (adminOneChildren && adminOneChildren.length > 0) {
                adminThreeChildren = adminOneChildren.flatMap((adminTwo: locationProps) => {
                  let c:string[][] = [];
                  const adminTwoChildren = adminTwo?.children;
                  if (adminTwoChildren && adminTwoChildren.length > 0) {
                    c = adminTwoChildren.map((adminThree: locationProps) => (
                      (flatOptTwo.some((i) => (i && i[0] !== adminThree.title)) && flatOptOne.some((i) => (i && i[0] !== adminThree.title))) ? [adminThree.title, opt.title] : []
                    ));
                  }
                  return c;
                });
              }
              return adminThreeChildren;
            });
          }
          return [...flatOptOne, ...flatOptTwo, ...flatOptThree];
        });
        y = y.filter((o: string[]|undefined) => (o && o[0] && o[1]));
        y = (y as string[][]).sort((a, b) => ((a[0]) > (b[0]) ? 1 : -1));
      }
      return x;
    },
  },
  methods: {
    applyFilters() {
      this.$store.dispatch('overviewFilter/updateData');
    },
    onClear() {
      if (this.getQueryDiff < 0) this.$store.dispatch('overviewFilter/updateData');
    },
  },
});

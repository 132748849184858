import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "SBY",
  ref: "SurveyByYearChart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Empty = _resolveComponent("Empty")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['sby-graph-container', _ctx.dataExists ? '' : 'sby-no-res'])
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [
      [_vShow, _ctx.dataExists]
    ]),
    (!_ctx.dataExists)
      ? (_openBlock(), _createBlock(_component_Empty, {
          key: 0,
          description: "No data to visualize"
        }))
      : _createCommentVNode("", true)
  ], 2))
}
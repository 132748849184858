import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "overview-container" }
const _hoisted_2 = { class: "overview-header-container" }
const _hoisted_3 = { class: "overview-filter-container" }
const _hoisted_4 = { class: "overview-metrics-container" }
const _hoisted_5 = { class: "overview-map-container" }
const _hoisted_6 = {
  class: "overview-graphs-container",
  "aria-label": "Overview graphs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_OverviewFilters = _resolveComponent("OverviewFilters")!
  const _component_OverviewMetrics = _resolveComponent("OverviewMetrics")!
  const _component_OverviewMap = _resolveComponent("OverviewMap")!
  const _component_OverviewGraphs = _resolveComponent("OverviewGraphs")!
  const _component_Spin = _resolveComponent("Spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Spin, {
      spinning: _ctx.spinning,
      size: "large",
      wrapperClassName: "view-spinner"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Header)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_OverviewFilters)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_OverviewMetrics)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_OverviewMap)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_OverviewGraphs)
        ])
      ]),
      _: 1
    }, 8, ["spinning"])
  ]))
}
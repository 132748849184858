
import { defineComponent, PropType } from 'vue';
import { Empty } from 'ant-design-vue';
import Plotly, { Data } from 'plotly.js-finance-dist-min';
import { overviewSurveyProps } from '@/utils/request';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'SurveyByYearGraph',
  props: {
    filter: {
      type: String,
    },
  },
  components: {
    Empty,
  },
  data() {
    return {
      dataExists: true,
    };
  },
  mounted() {
    const data:overviewSurveyProps = this.$store.getters['overviewFilter/getData'];
    if (data?.surveysByYear) {
      this.graph();
    }
  },
  computed: {
    ...mapGetters('overviewFilter', ['getLoading']),
  },
  methods: {
    renderSBY():Data[] {
      const data:overviewSurveyProps = this.$store.getters['overviewFilter/getData'];
      let x = Object.keys(data.surveysByYear);
      x = x.sort();
      const y = x.map((year) => (data.surveysByYear[year].total));
      this.dataExists = y.length > 0;
      const traces:Data = {
        x,
        y,
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        cliponaxis: false,
        type: 'scatter',
        fill: 'tozeroy',
        fillcolor: 'rgba(142,189,255,0.1)',
        name: 'All',
        marker: {
          color: '#75b3e9',
          symbol: 'circle',
        },
        hovertemplate: '    <span><span style="color: #d2d2d2">Year:</span>    %{x}  </span><br><span> <span style="color: #d2d2d2">Surveys:</span> <b>%{y}</b></span><extra></extra>',
        hoverinfo: 'x+y+name',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
      };

      return [traces];
    },
    renderSBQ():Data[] {
      const data:overviewSurveyProps = this.$store.getters['overviewFilter/getData'];
      let x = Object.keys(data.surveysByYear);
      x = x.sort();
      const good = x.map((year) => (data.surveysByYear[year].good));
      const goodPercent = x.map((year) => (((parseInt(data.surveysByYear[year].good, 10) / parseInt(data.surveysByYear[year].total, 10)) * 100) >> 0)); // eslint-disable-line no-bitwise
      const acceptable = x.map((year) => (data.surveysByYear[year].acceptable));
      const acceptablePercent = x.map((year) => (((parseInt(data.surveysByYear[year].acceptable, 10) / parseInt(data.surveysByYear[year].total, 10)) * 100) >> 0)); // eslint-disable-line no-bitwise
      const problematic = x.map((year) => (data.surveysByYear[year].problematic));
      const problematicPercent = x.map((year) => (((parseInt(data.surveysByYear[year].problematic, 10) / parseInt(data.surveysByYear[year].total, 10)) * 100) >> 0)); // eslint-disable-line no-bitwise
      this.dataExists = (good.length > 0) || (acceptable.length > 0) || (problematic.length > 0);
      const traces:Data = {
        x,
        y: good,
        customdata: goodPercent,
        textinfo: 'none',
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        type: 'scatter',
        name: 'Good',
        marker: {
          color: '#2caf80',
          symbol: 'circle',
        },
        connectgaps: true,
        hovertemplate: ' <b style="color: #2caf80; fontSize: 20px;">&#10687;</b>  <span><b>Good</b>  (n=%{y}, %{customdata}%)</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
      };
      const tracesTwo:Data = {
        x,
        y: acceptable,
        customdata: acceptablePercent,
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        type: 'scatter',
        name: 'Acceptable',
        marker: {
          color: '#e98300',
          symbol: 'circle',
        },
        connectgaps: true,
        hovertemplate: ' <b style="color: #e98300; fontSize: 20px;">&#10687;</b>  <span><b>Acceptable</b>  (n=%{y}, %{customdata}%)</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
      };
      const tracesThree:Data = {
        x,
        y: problematic,
        customdata: problematicPercent,
        textfont: {
          family: 'DINNextLTPro-Regular',
        },
        type: 'scatter',
        name: 'Problematic',
        marker: {
          color: '#e41919',
          symbol: 'circle',
        },
        connectgaps: true,
        hovertemplate: ' <b style="color: #e41919; fontSize: 20px;">&#10687;</b>  <span><b>Problematic</b>  (n=%{y}, %{customdata}%)</span><extra></extra>',
        hoverlabel: {
          bgcolor: '#363534',
          bordercolor: '#363534',
          font: {
            color: '#FFFFFF',
            family: 'DINNextLTPro',
            size: 12.5,
          },
        },
      };
      return [traces, tracesTwo, tracesThree];
    },
    graph() {
      const { width, height } = (this.$refs.SurveyByYearChart as Element).getBoundingClientRect();
      const layout = {
        width,
        height,
        xaxis: {
          showgrid: false,
          linecolor: '#e2e2e2',
          dtick: 1,
          tickfont: {
            color: 'rgba(54, 53, 52, 0.74)',
            family: 'DINNextLTPro-Regular, Arial',
            size: 12,
          },
        },
        yaxis: {
          tickfont: {
            color: 'rgba(54, 53, 52, 0.74)',
            family: 'DINNextLTPro-Regular, Arial',
          },
          rangemode: 'tozero',
          title: {
            text: 'Number of Surveys',
            font: {
              family: 'DINNextLTPro-Medium, Arial',
              size: 12,
              color: 'rgba(54, 53, 52, 0.74)',
            },
          },
        },
        legend: {
          xanchor: 'center',
          yanchor: 'top',
          font: {
            family: 'DINNextLTPro-Medium, Arial',
            size: 14,
          },
          y: -0.1,
          x: 0.5,
          orientation: 'h',
          margin: {
            l: 0,
            r: 0,
          },
        },
        margin: {
          t: 20,
          l: 35,
          r: 20,
          b: 80,
        },
      };
      let t: Data[] = [];
      if (this.filter === 'Overall') {
        t = this.renderSBY();
      } else {
        t = this.renderSBQ();
      }
      // @ts-expect-error enum error expected
      Plotly.newPlot('SBY', t, layout, { displayModeBar: false, responsive: true });
    },
  },
  watch: {
    getLoading(newProps, oldProps) {
      if (newProps === 'done' && oldProps === 'loading') {
        this.graph();
      }
    },
    filter(newProps, oldProps) {
      if (newProps !== oldProps) {
        this.graph();
      }
    },
  },
});

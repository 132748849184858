
import { defineComponent } from 'vue';
import TotalSurveys from './TotalSurveys.vue';
import TotalTrainings from './TotalTrainings.vue';
import TotalCountries from './TotalCountries.vue';
import TotalOrganizations from './TotalOrganizations.vue';

export default defineComponent({
  name: 'Metrics',
  components: {
    TotalOrganizations, TotalCountries, TotalTrainings, TotalSurveys,
  },
});

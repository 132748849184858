export const DEFAULT_MAP_STYLES = [
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#AAA6A4',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#dcd2be',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#AAA6A4',
      },
      {
        weight: '2',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#838383',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#141414',
      },
      {
        weight: '1',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e8eef7',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [
      {
        color: '#E8ECE6',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#b38484',
      },
      {
        lightness: '34',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#B6CBD5',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#649cf5',
      },
    ],
  },
];
export const DEFAULT_MAP_OPTIONS = {
  zoomControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  draggable: false,
  scrollwheel: false,
  disableDoubleClickZoom: true,
  gestureHandling: 'none',
  disableDefaultUI: true,
  keyboardShortcuts: false,
  styles: DEFAULT_MAP_STYLES,
};

export const regions = {
  EastAsiaAndPacific: {
    title: 'East Asia and the Pacific',
    position: { lat: 1.334396, lng: 122.210741 },
  },
  EuropeAndCentralAsia: {
    title: 'Europe and Central Asia',
    position: { lat: 62.499638, lng: 64.915041 },
  },
  MiddleEastAndNorthAfrica: {
    title: 'Middle East and North Africa',
    position: { lat: 26.873904, lng: 20.13261 },
  },
  WestAndCentralAfrica: {
    title: 'West and Central Africa',
    position: { lat: 10.024586, lng: 2.618767 },
  },
  EasternAndSouthernAfrica: {
    title: 'Eastern and Southern Africa',
    position: { lat: -7.258807, lng: 32.879037 },
  },
  LatinAmericaAndTheCaribbean: {
    title: 'Latin America and the Caribbean',
    position: { lat: -16.961802, lng: -71.388215 },
  },
  SouthAsia: { title: 'South Asia', position: { lat: 27.750506, lng: 76.533806 } },
  NorthAmerica: {
    title: 'North America',
    position: { lat: 47.853717, lng: -104.20131 },
  },
};


import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { Popover } from 'ant-design-vue';
import { overviewSurveyProps } from '@/utils/request';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TotalOrganizations',
  components: { InfoCircleOutlined, Popover },
  computed: {
    getMergedList():number {
      const trainOrgs = this.getTrainingOrganization;
      const data:overviewSurveyProps = this.$store.getters['overviewFilter/getData'];
      let survOrgs: string[] = [];
      if (data && data?.organizations) {
        survOrgs = Object.keys(data.organizations);
      }
      const mergedOrgs = Array.from(new Set(trainOrgs.concat(survOrgs)));
      return mergedOrgs.length;
    },
    getTrainingOrganization() {
      const values = this.$store.getters['overviewFilter/getTrainingData'];
      const orgs = [] as string[];
      if (values && values.length > 0) {
        const index = values[0].indexOf('Trainee Organizational Affliation (Specific Name)');
        if (index !== -1) {
          values.forEach((v: string[], i: number) => {
            if (i !== 0 && v.length >= index && v[index]) {
              orgs.push(v[index]);
            }
          });
        }
      }
      return orgs;
    },
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "metrics" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TotalSurveys = _resolveComponent("TotalSurveys")!
  const _component_TotalTrainings = _resolveComponent("TotalTrainings")!
  const _component_TotalCountries = _resolveComponent("TotalCountries")!
  const _component_TotalOrganizations = _resolveComponent("TotalOrganizations")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TotalSurveys),
    _createVNode(_component_TotalTrainings),
    _createVNode(_component_TotalCountries),
    _createVNode(_component_TotalOrganizations)
  ]))
}
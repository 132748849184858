
import { defineComponent } from 'vue';
import { Button, Popover } from 'ant-design-vue';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import SurveyByYearGraph from './SurveyByYear.vue';

export default defineComponent({
  name: 'SurveyByYear',
  components: {
    Button,
    SurveyByYearGraph,
    Popover,
    InfoCircleOutlined,
  },
  data() {
    return {
      filter: 'Overall',
    };
  },
});
